<template>
  <div class="comment" ref="comment">
    <!-- 评论输入框 -->
    <div class="input">
      <!-- 用户头像 -->
      <img
        :src="userInfoAvatar ? userInfoAvatar : require('@image/default.png')"
      />
      <div class="shuru">
        <textarea v-model="content" :placeholder="placeholder"></textarea>
        <div @click="findAddComment(1)" class="input-button">
          <i v-show="!request" class="el-icon-loading"></i>
          <span>评论</span>
        </div>
      </div>
    </div>

    <!-- 评论列表 -->
    <div class="commentsList" v-for="item in comments" :key="item.id">
      <!-- 评论区-->
      <div class="comments">
        <!-- <img :src="imgSrc" style="cursor: pointer" /> -->
        <img
          :src="
            item.doctor.photo
              ? item.doctor.photo
              : require('@image/default.png')
          "
          style="cursor: pointer"
        />

        <div class="comments-right" @mouseenter="showPerationItem(item.id)">
          <div class="top">
            <span>{{ item.doctor.name }}</span>
            <span class="time">{{ item.create_time | formaTime }}</span>
          </div>
          <div class="subTitle">{{ item.content }}</div>
          <div class="bottom">
            <span @click="selectUsre(item)">回复</span>

            <span v-if="!item.reply.length"></span>
            <span v-else-if="item.isShow" @click="funact(item)">
              <span class="bottom-number">收起回复</span>
              <div class="el-icon-arrow-up"></div>
            </span>
            <span v-else @click="funact(item)">
              <span class="bottom-number">{{ item.reply.length }}</span>
              <span>条回复</span>
              <div class="el-icon-arrow-down"></div>
            </span>

            <div class="operation">
              <span
                v-show="
                  (user_id == item.user_id && show_peration_id == item.id) ||
                  (release_id == user_id && show_peration_id == item.id)
                "
                @click="delete_comment(item.id, item.reply.length, item)"
                class="delete_comment"
              >
                <i title="删除评论" class="iconfont shanchu"></i>
                删除
              </span>

              <span
                v-show="user_id != item.user_id && show_peration_id == item.id"
                @click="chengReport(item.id, 2, item.user_id)"
              >
                <img src="@image/report.png" alt="" />
                举报
              </span>

              <span
                >{{ item.praise_num
                }}<i
                  @click="like(item.praise, item)"
                  class="iconfont iconzan2"
                  :class="{ zanhover: item.praise }"
                ></i
              ></span>
            </div>
          </div>
        </div>
      </div>

      <!-- 回复输入框 -->
      <div class="apply_input" v-show="isShow == item.id">
        <input
          v-model="apply_content"
          type="text"
          :placeholder="['回复 : '] + item.doctor.name"
          autocomplete="off"
        />
        <div class="input-button">
          <i v-show="!request" class="el-icon-loading"></i>
          <span @click="findAddComment(2)">评论</span>
        </div>
      </div>

      <!-- 子评论区 -->
      <div
        class="pull-down"
        :style="{ width: width + 'px' }"
        v-show="item.isShow"
        ref="pull_down"
      >
        <div class="down-comments" v-for="it in item.reply" :key="it.id">
          <div class="down-comments-item">
            <img
              :src="
                it.doctor.photo
                  ? it.doctor.photo
                  : require('@image/default.png')
              "
            />

            <div class="comments-right" @mouseenter="showPerationItem(it.id)">
              <div class="top">
                <span>{{ it.doctor.name }}</span>
                <span class="reply">回复</span>
                <span>{{ it.reply.name }}</span>
                <span class="time">{{ it.create_time | formaTime }}</span>
              </div>
              <div class="subTitle">{{ it.content }}</div>
              <div class="bottom">
                <span class="curs" @click="selectUsre(it)">回复</span>

                <div class="operation">
                  <span
                    v-show="
                      (user_id == it.user_id && show_peration_id == it.id) ||
                      (release_id == user_id && show_peration_id == it.id)
                    "
                    :data-length="item.reply.length"
                    @click="delete_comment(it.id, item.reply.length, item)"
                    class="delete_comment"
                  >
                    <i title="删除评论" class="iconfont shanchu"></i>
                    删除
                  </span>

                  <span
                    v-show="user_id != it.user_id && show_peration_id == it.id"
                    @click="chengReport(it.id, 2, it.user_id)"
                  >
                    <img src="@image/report.png" alt="" />
                    举报
                  </span>

                  <span
                    >{{ it.praise_num
                    }}<i
                      @click="like(it.praise, it)"
                      class="iconfont iconzan"
                      :class="{ zanhover: it.praise }"
                    ></i
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <!-- 评论他人的输入框 -->
          <div class="apply_input" v-show="isShow == it.id">
            <input
              v-model="apply_content"
              type="text"
              :placeholder="['回复 : '] + it.doctor.name"
              autocomplete="off"
            />
            <div class="input-button">
              <i v-show="!request" class="el-icon-loading"></i>
              <span @click="findAddComment(2)">评论</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  // 父组件传值
  props: {
    width: {
      type: [Number, String],
      define: "849",
    },
    discover_id: {
      type: [Number, String],
      define: 0,
    },

    release_id: {
      type: [Number, String],
      define: 0,
    },

    news_id: {
      type: [Number, String],
      define: 0,
    },
    type: {
      type: [Number, String],
      define: "",
    },
    answer_id: {
      type: [Number, String],
      define: 0,
    },
    comments: {
      type: Array,
      define: [],
    },
    is_drug: {
      type: Boolean,
      define: false,
    },
  },
  data() {
    return {
      request: true,
      userItem: "", // 回复的用户
      placeholder: "说说你的看法",
      findDetail: [],
      content: "",
      apply_content: "",
      height: 0,
      isBtn: false,
      isShow: "",
      user_id: "",
      show_peration_id: "",
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userManagement.userInfo,
    }),

    userInfoAvatar() {
      return this.userInfo.avatar
        ? this.userInfo.avatar
        : this.$http.baseURL + "/static/images/web/1.png";
    },
  },

  mounted() {
    // 组建距离顶部高度
    this.height = this.$refs.comment.getBoundingClientRect().height;
    if (localStorage.getItem("token")) {
      this.getUserInfo();
    }
    if (this.userInfo.info) {
      this.user_id = this.userInfo.info.user_id;
    }
  },

  methods: {
    ...mapActions("userManagement", ["getUserInfo"]),
    // 控制pull-dowm的显示与隐藏
    funact(item) {
      item.isShow = !item.isShow;
      this.$forceUpdate();
    },

    // 删除功能
    delete_comment(id, length, item) {
      // 删除确定框
      return this.$confirm("此操作将删除该评论, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          // 触发父组件的删除操作
          this.isShow = "";
          this.$emit("delete_comment", id);
          // 删除最后一个的时候隐藏pull-down
          if (length == 1) {
            item.isShow = !item.isShow;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 点赞
    async like(type, item) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }

      let data = {
        like_id: item.id,
        type: 2,
      };
      if (this.request) {
        this.request = false;
        let res;
        if (this.is_drug) {
          res = await this.$findApi.drugCommentLike(data);
        } else {
          res = await this.$findApi.findCommentLike(data);
        }
        this.request = true;
        if (res.code == 200) {
          if (type) {
            item.praise_num -= 1;
            this.$message({
              message: "已取消点赞",
              type: "success",
            });
          } else {
            item.praise_num += 1;
            this.$message({
              message: "点赞成功",
              type: "success",
            });
          }
          item.praise = !type;
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      }
    },

    // 举报
    chengReport(discover_id, type, user_id) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      } else {
        this.bus.$emit("report", discover_id, type, user_id);
      }
    },

    // 鼠标移入显示举报和删除
    showPerationItem(id) {
      this.show_peration_id = id;
    },

    // 发表评论
    async findAddComment(form) {
      // 判断是否登录
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      // data数据收集
      let { discover_id, news_id, type } = this;
      let data = {
        discover_id,
        news_id,
        type,
      };
      // 回复文章
      if (form == 1) {
        data.content = this.content;
      } else if (form == 2) {
        // 回复评论
        data.content = this.apply_content;
        data.comment_id = this.userItem.comment_id || this.userItem.id;
        data.reply_user_id = this.userItem.doctor.user_id;
      }
      // 输入内容非空判断
      if (!data.content) {
        this.$message.error("评论内容不能为空！");
        return false;
      }
      if (Number(this.answer_id)) {
        data.answer_id = this.answer_id;
      }
      // 发送请求
      if (this.request) {
        this.request = false;
        let res;
        if (this.is_drug) {
          res = await this.$findApi.drugAddComment(data);
        } else {
          res = await this.$findApi.findAddComment(data);
        }
        if (res.code == 200) {
          this.content = "";
          this.apply_content = "";
          this.$emit("refresh");
          this.$message({
            message: "评论成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        this.request = true;
        this.isShow = "";
      }
    },

    // 回复他人输入框
    selectUsre(item) {
      this.apply_content = "";
      this.userItem = item;
      if (this.isShow == item.id) {
        this.isShow = "";
      } else {
        this.isShow = item.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.curs {
  cursor: pointer;
}

.iconfont {
  font-size: 20px;
}

.zanhover {
  color: red;
}

textarea {
  &:focus {
    outline: none;
  }
}

.comment {
  display: flex;
  flex-direction: column;

  > .input {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 30px;
    // border-bottom: 1px solid #f2f2f2;
    padding-bottom: 30px;
    box-sizing: border-box;

    > img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }

    > .shuru {
      width: 100%;
      background: #f2f2f2;
      height: 150px;
      margin-left: 10px;

      textarea {
        width: 100%;
        border: 0;
        background: #f2f2f2;
        height: 110px;
        resize: none;
        padding: 10px;
        box-sizing: border-box;
        font-size: 16px;
      }

      .input-button {
        cursor: pointer;
        position: absolute;
        width: 56px;
        height: 25px;
        background: #8f8f8f;
        border-radius: 5px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        right: 20px;
        top: 115px;
      }
    }
  }

  > .commentsList {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #f2f2f2;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: 1px solid #f2f2f2;
    }

    > .comments {
      display: flex;
      flex-direction: row;
      // border-bottom: 1px solid #f2f2f2;
      padding-bottom: 30px;
      box-sizing: border-box;
      margin-top: 30px;

      > img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
      }

      > .comments-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 10px;

        > .top {
          flex-direction: row;
          color: #333333;
          font-size: 16px;

          > .time {
            color: #d9d9d9;
            margin-left: 30px;
          }

          > .delete_comment {
            float: right;
            padding-right: 40px;
            cursor: pointer;
          }
        }

        > .subTitle {
          font-size: 18px;
          color: #333333;
          margin-top: 10px;
        }

        > .bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #8f8f8f;
          font-size: 16px;
          margin-top: 17px;
          cursor: pointer;

          .bottom-number {
            margin-left: 40px;
          }

          .el-icon-arrow-down {
            margin-left: 11px;
          }

          .el-icon-arrow-up {
            margin-left: 11px;
          }

          .operation {
            display: flex;
            align-items: center;
            margin-right: 41px;
            font-size: 16px;
            margin-left: auto;

            > span {
              margin-right: 10px;
              > img {
                vertical-align: bottom;
                width: 18px;
              }
            }
          }
        }
      }
    }

    > .pull-down {
      display: flex;
      flex-direction: column;

      background: #fafafa;
      padding: 20px 0 20px 20px;
      box-sizing: border-box;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: auto;

      > .down-comments {
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 10px;

        .down-comments-item {
          display: flex;
          flex-direction: row;
          padding-bottom: 20px;
          box-sizing: border-box;

          &:last-child {
            border-bottom: none;
            margin-bottom: 0;
          }

          &:last-child {
            padding-bottom: 0;
          }

          > img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }

          > .comments-right {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-left: 10px;

            > .top {
              flex-direction: row;
              color: #333333;
              font-size: 16px;

              > .time {
                color: #d9d9d9;
                margin-left: 30px;
              }

              > .reply {
                color: #8f8f8f;
                margin: 0 20px;
              }

              > .delete_comment {
                float: right;
                padding-right: 40px;
                cursor: pointer;
              }
            }

            > .subTitle {
              font-size: 18px;
              color: #333333;
              margin-top: 10px;
            }

            > .bottom {
              display: flex;
              flex-direction: row;
              align-items: center;
              color: #8f8f8f;
              font-size: 16px;
              margin-top: 17px;

              > .bottom-number {
                margin-left: 40px;
              }

              > .el-icon-arrow-down {
                margin-left: 11px;
              }

              > .operation {
                display: flex;
                align-items: center;
                margin-right: 41px;
                font-size: 16px;
                margin-left: auto;
                cursor: pointer;
                > span {
                  margin-right: 10px;
                  > img {
                    vertical-align: bottom;
                    width: 18px;
                  }
                }

                .icon {
                  font-size: 26px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 回复他人
.apply_input {
  padding: 20px 20px 50px;
  background: #efefef;
  margin: 0px 30px 30px 50px;

  > input {
    box-sizing: border-box;
    color: #626262;
    font-weight: 400;
    font-size: 16px;
    border-radius: 3px;
    width: 100%;
    padding: 8px 15px;
    outline: none;
    border: 1px solid #ccc;

    &:focus {
      border: 1px solid #1cd4b8;
    }
  }

  > .input-button {
    cursor: pointer;
    position: absolute;
    width: 56px;
    height: 25px;
    background: #8f8f8f;
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    right: 20px;
    top: 70px;
  }
}
</style>
