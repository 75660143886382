<template>
  <div class="page">
    <div class="dkzc-box">
      <div class="left">
        <!-- 标题 -->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/index' }">创作</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 个人信息 -->
        <div class="personage">
          <img src="@image/touxiang.png" />
          <div class="personage-right">
            <div class="one">
              <span class="title">刘旭</span>
              <span class="position">主任医师</span>
              <span class="button">+关注</span>
            </div>
            <div class="two">
              <span class="site">上海市瑞金医院</span>
              <span>心血管内科</span>
            </div>
          </div>
        </div>
        <!-- 简介 -->
        <div class="content">
          <span class="subTitle"
            >脑血管狭窄引起头疼应该注意些什么脑血管狭窄引起头疼脑血管狭窄引起头脑血管狭窄引起头狭窄引起头脑血管狭窄引起脑血管
            意些什么脑血管狭窄引起头疼应该注意些什么脑血管狭窄引起头疼应该注意些什么血管狭窄引意些什么脑血管狭窄引起头脑血管
            狭窄引起头疼应该注意些什么血管狭窄引起头疼脑血管狭窄引起
          </span>
          <img class="banner" src="@image/newsDetail-banner.jpg" />
          <span class="contentTime">2021-01-15 10:00:00</span>
        </div>
        <!-- 点赞收藏图标区 -->
        <div class="icons">
          <div class="favoritesNumber">
            <i
              @click="favorites = !favorites"
              class="iconfont"
              :class="{ shoucanghover: favorites, shoucang: !favorites }"
            ></i>
            <span>100</span>
          </div>
          <div class="like">
            <i
              @click="star = !star"
              class="iconfont"
              :class="{ zan1: star, iconzan2: !star }"
            ></i>
            <span>100</span>
          </div>
          <span class="share">分享：</span>
          <share :config="config"></share>
          <!-- <img class="weixin" src="@image/weixin.svg" />
          <img class="qq" src="@image/qq.svg" />
          <img class="weibo" src="@image/weibo.svg" /> -->
        </div>
        <!-- 评论数 -->
        <div class="numberComments" style="width">
          <span class="number">28</span>
          <span>条评论</span>
        </div>
        <comment width="840"></comment>
      </div>
      <!-- 右侧信息区 -->
      <div class="right-group">
        <div class="group" v-for="(item, index) in 4" :key="index">
          <div class="group-left">
            <span class="Play">12:11</span>
            <img class="information" src="@image/xinxi.jpg" />
          </div>
          <div class="right">
            <span class="title">怀孕双胞胎多久能查出</span>
            <div class="site">
              <span>上海红房子医院</span>
              <span class="name">肖喜荣</span>
            </div>
            <div class="time">
              <span>45.7万</span>
              <span>次观看</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import comment from "@components/common/comment";
export default {
  components: { comment },
  data() {
    return {
      text: "",
      isShow: false,
      isShow2: false,
      star: false,
      favorites: false,
      num: 5,
      list: [
        {
          img: require("@image/shiping-banner.png"),
        },
        {
          img: require("@image/shiping-banner2.png"),
        },
        {
          img: require("@image/shiping-banner3.png"),
        },
        {
          img: require("@image/shiping-banner4.png"),
        },
        {
          img: require("@image/shiping-banner5.png"),
        },
        {
          img: require("@image/shiping-banner6.png"),
        },
      ],
      config: {
        url: window.location.href,
        sites: ["wechat", "qq", "weibo"],
        wechatQrcodeTitle: "微信扫一扫：分享",
        wechatQrcodeHelper:
          "<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.iconfont {
  font-size: 24px;
}
video,
textarea {
  &:focus {
    outline: none;
  }
}
.page {
  > .dkzc-box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;
    > .left {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      width: 890px;
      > .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-right: 10px;
      }
      > .personage {
        display: flex;
        flex-direction: row;
        align-items: center;
        > img {
          width: 70px;
          height: 70px;
          object-fit: cover;
        }
        > .personage-right {
          display: flex;
          flex-direction: column;
          margin-left: 12px;
          width: 100%;
          > .one {
            display: flex;
            flex-direction: row;
            color: #333333;
            font-size: 24px;
            > .position {
              font-size: 13px;
              color: $--themeColor;
              width: 75px;
              height: 25px;
              border: 1px solid $--themeColor;
              border-radius: 6px;
              text-align: center;
              line-height: 26px;
              margin-left: 12px;
            }
            > .button {
              font-size: 12px;
              color: #8f8f8f;
              width: 66px;
              height: 25px;
              background: #ffffff;
              border: 1px solid #8f8f8f;
              border-radius: 6px;
              text-align: center;
              line-height: 24px;
              margin-left: auto;
            }
          }
          > .two {
            display: flex;
            flex-direction: row;
            color: #8f8f8f;
            font-size: 16px;
            margin-top: 9px;
            > .site {
              margin-right: 20px;
            }
          }
        }
      }
      > .content {
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 24px;
        > .subTitle {
          line-height: 1.8;
          color: #333333;
          font-size: 18px;
          overflow: hidden;
          /*必须结合的属性,当内容溢出元素框时发生的事情*/
          text-overflow: ellipsis;
          /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
          display: -webkit-box;
          /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
          -webkit-line-clamp: 5;
          /*用来限制在一个块元素显示的文本的行数。*/
          -webkit-box-orient: vertical;
          margin-top: 20px;
        }
        > .banner {
          height: 390px;
          object-fit: cover;
          margin-top: 10px;
        }
        > .contentTime {
          font-size: 14px;
          color: #8f8f8f;
          margin-top: 10px;
        }
      }
      > .icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #8f8f8f;
        font-size: 24px;
        margin-top: 33px;
        cursor: pointer;
        > .favoritesNumber {
          margin-right: 40px;
          .shoucanghover {
            color: #e60012;
          }
        }
        > .like {
          .zan1 {
            color: #e60012;
          }
        }
        > .share {
          font-size: 16px;
          margin-left: auto;
        }
        > .qq {
          margin: 0 20px;
        }
      }
      > .numberComments {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #333333;
        font-size: 24px;
        margin-top: 30px;
        padding-bottom: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f2;
        > .number {
          font-size: 30px;
          color: #e60012;
          margin-right: 5px;
        }
      }
    }
    > .right-group {
      display: flex;
      flex-direction: column;
      width: 294px;
      height: min-content;
      background: #f2f2f2;
      padding: 15px;
      box-sizing: border-box;
      margin-top: 30px;
      > .group {
        margin-bottom: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: row;

        &:not(:last-child) {
          border-bottom: 1px dashed #000;
        }

        &:last-child {
          margin-bottom: 0;
        }
        > .group-left {
          display: flex;
          position: relative;
          > .information {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
            margin-right: 10px;
          }
          > .Play {
            position: absolute;
            font-size: 12px;
            color: #ffffff;
            width: 40px;
            height: 18px;
            background: #000000;
            border-radius: 5px;
            text-align: center;
            line-height: 18px;
            left: 57px;
            top: 79px;
          }
        }
        > .right {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          color: #8f8f8f;
          > .title {
            color: #333333;
            line-height: 20px;
          }
          > .site {
            margin-top: 5px;
            > .name {
              margin-left: 8px;
            }
          }
          > .time {
            margin-top: auto;
          }
        }
      }
    }
  }
}
</style>
